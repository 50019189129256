
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
   props: ["data"],
  setup(props, { emit }) {   
    const sizeSet: any = ref(props.data);
    const store = useStore();
    store.dispatch(Actions.SIZE_SET_LIST);
    const sizeSetList = computed(
      () => store.state.SizeModule.sizeSet.value || []
    );

    const onChange = (args) => {
      emit("changeSizeSet",args);
    };
    return { close, sizeSet, store, sizeSetList, onChange };
  },
};
