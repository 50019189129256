
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data", "id","orderDetailId"],
  setup(props, { emit }) {
    const reasonId: any = ref(props.id);
    const store = useStore();
    store.dispatch(Actions.DIFFERENCE_REASON_LIST);
    const differenceReasonList = computed(
      () => store.state.DifferenceReasonModule.differenceReason.value || []
    );
    const differenceReason= ref(props.data);    
    const onChange = (args) => {      
     
      if(args.value){        
         var model = {
        name:args.value.name,
        value: args.value.value,
        orderDetailId : props.orderDetailId
      }
        emit("changeDifferenceReason", model);
      } else {        
        emit("changeDifferenceReason", {
          orderDetailId: props.orderDetailId,value:null
        });
      }
      
    };
    return {
      close,
      differenceReason,
      store,
      differenceReasonList,
      onChange,
      reasonId,
    };
  },
};
