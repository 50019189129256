import { useToast } from "primevue/usetoast";

export default function useNotification() {
  const toast = useToast();
  const showToast = (severity, message) => {
    toast.add({
      severity: severity,
      summary: "İşlem Sonucu",
      detail: message,
      life: 5000,
    });
  };

  return {
    showMessage: (message) => {
      showToast("success", message);
    },
    showError: (message) => {
      showToast("error", message);
    },
    showWarning: (message) => {
      showToast("warn", message);
    },
    showInfo: (message) => {
      showToast("info", message);
    },
  };
}
